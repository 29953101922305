import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Areas from "../components/independents/Areas"
import getListingSlug from "../utils/getListingSlug"

const IndyWrapper = styled.div`
  .featuredBusiness {
    padding: 4rem 0;
    position: relative;

    @media only screen and (min-width: 1024px) {
      padding: 10rem 2rem 6rem 2rem;
    }
  }
`
export default function AreasTemplate(data) {
  const listingsData = data.data
  const seo = listingsData.seo
  const area = listingsData.area.name
  const areaImage = listingsData.area.image
  const offersState = false
  const basicListings = listingsData.listings.nodes
  const editorial = listingsData.editorial.acf_areas.editorial
  const premiumListings = listingsData.premiumListings.nodes
  const what = listingsData.filterWhat
  const where = listingsData.filterWhere
  const map = listingsData.map

  // Create an array of all listings for use in State and Map
  const allListings = []
  premiumListings.map(item => {
    return allListings.push(item)
  })

  basicListings.map(item => {
    return allListings.push(item)
  })

  const offerListings = []

  allListings.map(item => {
    return item?.acf_listings?.blackCardPromotion !== null
      ? offerListings.push(item)
      : ""
  })

  // // Restructure array data for use in Slider

  const slider1Array = []
  listingsData.sliders1.acf_areas.slider1SliderAreas.map((item, index) => {
    const postCat = item.pickAListing.terms.nodes.filter(
      value => Object.keys(value).length !== 0
    )

    return slider1Array.push({
      title: item?.pickAListing?.title,
      excerpt: item?.pickAListing?.excerpt,
      link: "/" + postCat[0].slug + "/" + item?.pickAListing?.slug,
      acf_customFeaturedImage: item?.pickAListing?.acf_customFeaturedImage,
      image: null,
      intOrExt: "internal",
      prefix: "",
    })
  })

  // Restructure array data for use in Slider
  const slider2Array = []

  listingsData.sliders2.acf_areas.slider2SliderAreas.map((item, index) => {
    if (item.pickAListing !== null) {
      //console.log("👍", item)
      return slider2Array.push({
        title: item?.pickAListing?.title,
        excerpt: item?.pickAListing?.acf_listings?.blackCardPromotion,
        link: getListingSlug(item?.pickAListing),
        acf_customFeaturedImage: null,
        image: item?.pickAListing?.acf_listings?.image1,
        intOrExt: "internal",
        prefix: "",
      })
    }
  })
  //console.log(slider1Array, slider2Array)
  return (
    <IndyWrapper>
      <Areas
        listingsData={listingsData}
        seo={seo}
        offersState={offersState}
        basicListings={basicListings}
        allListings={allListings}
        offerListings={offerListings}
        premiumListings={premiumListings}
        what={what}
        where={where}
        map={map}
        data={data}
        area={area}
        areaImage={areaImage}
        slider1Array={slider1Array}
        slider2Array={slider2Array}
        showLocationsFilter={false}
        editorial={editorial}
      />
    </IndyWrapper>
  )
}

export const pageQuery = graphql`
  query AreasArchive($slug: String!) {
    seo: wpPage(slug: { eq: $slug }) {
      seo {
        metaDesc
        title
        metaRobotsNofollow
        metaRobotsNoindex
        metaKeywords
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          localFile {
            id
          }
        }
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphUrl
        twitterTitle
        twitterDescription
      }
    }

    og: wp {
      options {
        acf_options {
          ogIndependentsMapOffers {
            mediaItemUrl
          }
        }
      }
    }

    #================================
    # Map
    #================================
    map: wpListingTagLocation(slug: { eq: $slug }) {
      acf_listing_location_fields {
        lat
        lng
      }
    }

    #================================
    # Area
    #================================
    area: wpListingTagLocation(slug: { eq: $slug }) {
      name
      image: acf_listing_location_fields {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }

    #================================
    # BASIC LISTINGS
    #================================
    listings: allWpListing(
      filter: {
        listingsTagLocation: { nodes: { elemMatch: { slug: { eq: $slug } } } }
        reduceToBasicListing: { profileType: { eq: "basic" } }
      }
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        link
        slug
        title
        reduceToBasicListing {
          profileType
        }
        listingsTagLocation {
          nodes {
            name
          }
        }
        listingsCategories {
          nodes {
            name
          }
        }
        listingsTagLocation {
          nodes {
            slug
            wpParent {
              node {
                slug
              }
            }
          }
        }
        acf_listings {
          latitude
          longitude
          taglineSummary
          blackCardPromotion
          address
          image1 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }

    premiumListings: allWpListing(
      filter: {
        listingsTagLocation: {
          nodes: { elemMatch: { slug: { eq: "ilkley" } } }
        }
        reduceToBasicListing: { profileType: { eq: "full" } }
      }
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        link
        slug
        title
        reduceToBasicListing {
          profileType
        }
        listingsTagLocation {
          nodes {
            name
          }
        }
        listingsCategories {
          nodes {
            name
          }
        }
        listingsTagLocation {
          nodes {
            slug
            wpParent {
              node {
                slug
              }
            }
          }
        }
        acf_listings {
          latitude
          longitude
          taglineSummary
          blackCardPromotion
          address
          image1 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }

    #================================
    # WHAT FILTERS
    #================================

    filterWhat: allWpListingsCategory {
      nodes {
        slug
        name
      }
    }

    #================================
    # WHERE FILTERS
    #================================
    filterWhere: allWpListingTagLocation {
      nodes {
        slug
        name
        acf_listing_location_fields {
          lat
          lng
        }
      }
    }

    #================================
    # FEATURED BUSINESS SECTION
    #================================
    featuredBusiness: wpPage(slug: { eq: $slug }) {
      acf_independent_page_fields {
        chooseFeaturedBusiness {
          ... on WpListing {
            id
            title
            link
            slug
            listingsTagLocation {
              nodes {
                slug
                wpParent {
                  node {
                    slug
                  }
                }
              }
            }
            acf_listings {
              address
              phone
              blackCardPromotion
              fullText
              image1 {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }

    editorial: wpPage(slug: { eq: $slug }) {
      acf_areas {
        editorial {
          ... on WpPage_AcfAreas_Editorial_Text {
            fieldGroupName
            text
          }
          ... on WpPage_AcfAreas_Editorial_ImageText {
            fieldGroupName
            imageText {
              text
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on WpPage_AcfAreas_Editorial_Image {
            fieldGroupName
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }

    # #================================
    # # SLIDER 1
    # #================================
    sliders1: wpPage(slug: { eq: $slug }) {
      acf_areas {
        slider1Heading
        slider1SliderAreas {
          pickAListing: pickAnArticle {
            ... on WpPost {
              id
              title
              uri
              slug
              excerpt
              terms {
                nodes {
                  ... on WpCategory {
                    id
                    name
                    slug
                  }
                }
              }
              acf_customFeaturedImage {
                featuredImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    #================================
    # SLIDER 2
    #================================
    sliders2: wpPage(slug: { eq: $slug }) {
      acf_areas {
        slider2Heading
        slider2SliderAreas {
          pickAListing: pickAnOffer {
            ... on WpListing {
              id
              title
              uri
              slug
              listingsTagLocation {
                nodes {
                  slug
                  wpParent {
                    node {
                      slug
                    }
                  }
                }
              }
              acf_listings {
                blackCardPromotion
                image1 {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
